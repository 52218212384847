<template>
  <vs-popup
    title="Upload new document"
    :buttons-hidden="true"
    @close="init"
    :active.sync="activePrompt">
    <div>
      <div>
        <form>
          <div class="px-8 pt-4">
            <input type="file" ref="fileInput" multiple class="hidden" @change="handleClick">
            <div
              @click="$refs.fileInput.click()"
              @drop="handleDrop"
              @dragover="handleDragover"
              @dragenter="handleDragover"
              class="px-4 py-8 cursor-pointer w-full text-center border-2 border-dashed d-theme-border-grey-light d-theme-dark-bg text-xl">
              <feather-icon icon="UploadCloudIcon" svgClasses="h-16 w-16 stroke-current text-grey" class="block" />
              <span>Drop File or </span>
              <vs-button type="border" @click.stop="$refs.fileInput.click()">Browse</vs-button>
            </div>
          </div>
        </form>
        <div class="px-8 mb-4 mt-4">
          <vs-row :key="index" v-for="(file, index) in this.allFileList" vs-type="flex" vs-justify="space-between">
            <vs-col vs-type="flex" style="display:flex" vs-w="10">{{index+1}}. {{file.name}}</vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
              <vx-tooltip
                :text="getUploadFileAttr(file, 'title')"
                position="left"
              >
                <feather-icon
                  @click.stop="onClickUploadFileIcon(file, index)"
                  :icon="getUploadFileAttr(file, 'icon')"
                  svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2"
                />
              </vx-tooltip>
            </vs-col>
          </vs-row>
        </div>
      </div>
      <footer class="pr-8 pull-right">
        <button :disabled="processing === 1" id="document-upload-btn" class="vs-con-loading__container vs-component
                vs-button vs-dialog-accept-button vs-button-primary vs-button-border" @click="execute"
                name="button">Upload</button>
      </footer>
    </div>
  </vs-popup>
</template>

<script>
  import axios from '@/axios.js'
  import _ from 'lodash'

  export default {
    props: {
      displayPrompt: {
        type: Boolean,
        required: true
      },
      taskRecord: {
        required: true
      },
    },
    data() {
      return {
        processing : 0,
        allFileList: [],
        newFileList: []
      }
    },
    mounted() {
      this.newFileList = []
      this.allFileList = []
    },
    computed: {
      activePrompt: {
        get () {
          return this.displayPrompt
        },
        set (value) {
          this.$emit('hideDisplayPrompt', value)
        }
      },
    },
    methods: {
      getUploadFileAttr(fileItem, attr) {
        if (attr === 'title') {
          if (fileItem.isQueued) return "Queued";
          if (fileItem.isUploading) return "Uploading";
          if (fileItem.isComplete) return "Uploaded";
          if (fileItem.isFailed) return "Failed";
        } else if (attr === 'icon') {
          if (fileItem.isQueued) return "TrashIcon";
          if (fileItem.isFailed) return "XIcon";
          if (fileItem.isUploading) return "UploadCloudIcon";
          if (fileItem.isComplete) return "CheckIcon";
        }
        return "";
      },
      onClickUploadFileIcon(file, allIndex) {
        let index = _.findIndex(this.newFileList, {name: file.name})
        if (index > -1 && (file.isQueued || file.isFailed) && allIndex > -1 && this.processing === 0) {
          this.newFileList.splice(index, 1);
          this.allFileList.splice(allIndex, 1);
        }
      },
      execute() {
        this.processing = 1;
        this.$vs.loading({
          container: "#document-upload-btn",
          scale: 0.45
        })
        let uploadUrl = "accounts/" + this.taskRecord.accountId + "/cases/" + this.taskRecord.uuid + "/documents"
        let urlParams = {
          files: []
        };

        this.newFileList
          .filter(file => file.name)
          .map(file => {
            let fileParams = {
              name: file.name
            };
            if (file.type) fileParams.type = file.type;
            urlParams.files.push(fileParams);
          });
        let counts = {success: 0, failed: 0, total: urlParams.files.length}
        axios.post(uploadUrl, urlParams)
          .then(response => {
            if (response && response.data && response.data.success && response.data.files) {
              Promise.all(
                response.data.files
                  .filter(file => file.uploadUrl && file.name)
                  .map(file => {
                    return new Promise((resolve) => {
                      let index = _.findIndex(this.allFileList, {name: file.name});
                      let newIndex = _.findIndex(this.newFileList, {name: file.name});
                      if (index > -1 && newIndex > -1) {
                        this.allFileList[index].isQueued = false
                        this.allFileList[index].isFailed = false
                        this.allFileList[index].isUploading = true
                        console.log(this.allFileList[index], this.newFileList[newIndex]);
                        this.newFileList[newIndex].arrayBuffer()
                          .then(arrayBuffer => {
                            console.log(arrayBuffer);
                            const headers = {}, body = {};
                            if (file.type) headers['Content-Type'] = file.type;
                            if (file.type) body['type'] = file.type;
                            axios.put(file.uploadUrl, new Blob([arrayBuffer], body), {
                              headers: headers
                            }).then(response => {
                              counts.success += 1;
                              this.allFileList[index].isUploading = false
                              this.allFileList[index].isComplete = true
                              resolve()
                            }).catch(error => {
                              counts.failed += 1;
                              this.allFileList[index].isUploading = false
                              this.allFileList[index].isFailed = true
                              resolve()
                            })
                          })
                      } else resolve()
                    })
                  })
              ).then(response => {
                this.processing = 0;
                let successFiles = counts.success, totalFiles = counts.total;
                let newFileList = []
                this.allFileList
                  .filter(file => file.isFailed)
                  .map(file => {
                    let index = _.findIndex(this.newFileList, {name: file.name});
                    if (index > -1) {
                      newFileList.push(this.newFileList[index]);
                    }
                  });
                this.newFileList = newFileList;
                this.$vs.loading.close("#document-upload-btn > .con-vs-loading")
                this.$vs.notify({
                  color: 'success',
                  title: (successFiles === totalFiles ? "Selected" : (successFiles + "/" + totalFiles)) + " files uploaded successfully.",
                  text: 'The file was successfully updated.'
                })
              }).catch(error => {
                this.processing = 0;
                this.$vs.loading.close("#document-upload-btn > .con-vs-loading")
              })
            } else {
              this.processing = 0;
              this.$vs.loading.close("#document-upload-btn > .con-vs-loading")
            }
          }).catch(error => {
            this.processing = 0;
            this.$vs.loading.close("#document-upload-btn > .con-vs-loading")
          })
      },
      init() {

      },
      handleDrop(e) {
        e.stopPropagation()
        e.preventDefault()
        if (this.processing === 0 && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
          for (let i = 0; i < e.dataTransfer.files.length; i++) {
            this.allFileList.unshift({isUploading: false, isComplete: false, isFailed: false, isQueued: true, name: e.dataTransfer.files[i].name})
            this.newFileList.push(e.dataTransfer.files[i])
          }
        }
      },
      handleDragover(e) {
        e.stopPropagation()
        e.preventDefault()
        e.dataTransfer.dropEffect = 'copy'
      },
      handleClick(e) {
        console.log(e.target.files)
        if (this.processing === 0 && e.target.files && e.target.files.length > 0) {
          for (let i = 0; i < e.target.files.length; i++) {
            this.allFileList.unshift({isUploading: false, isComplete: false, isFailed: false, isQueued: true, name: e.target.files[i].name})
            this.newFileList.push(e.target.files[i])
          }
        }
      },
    }
  }
</script>

<style lang="scss">
  .pull-right {
    float: right;
  }
</style>
